<template>
  <div class="exchange-record">
    <div class="card recommond-my">
      <div class="text-grey-dark title-md text-bold">
        {{ $t("exchange_history") }}
      </div>
      <el-table
        :stripe="true"
        :data="list"
        style="width: 100%;margin-top:20px"
        v-loading="loading"
        element-loading-background="rgba(0, 0, 0, 0)"
      >
        <el-table-column
          :show-overflow-tooltip="true"
          prop="orderDate"
          :label="$t('trading_time')"
          width="180"
        >
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="orderId"
          :label="$t('order_id')"
          width="180"
        >
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="giftName"
          :label="$t('prize_name')"
          width="240"
        >
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="displayPoints"
          :label="$t('consum_gold')"
          width="120"
        >
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="status"
          :label="$t('order_status')"
          width="120"
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.status !== '-'"
              :type="getScopType(scope.row.status)"
              effect="dark"
              size="mini"
              >{{ scope.row.status }}</el-tag
            >
            <span v-if="scope.row.status === '-'">-</span>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="memo"
          :label="$t('order_note')"
          width="150"
        >
        </el-table-column>
      </el-table>
      <div class="mt-20">
        <el-pagination
          background
          layout="prev, pager, next,total"
          :total="pagination.totalSize"
          :page-size="pagination.pageSize"
          :current-page="pagination.currentPage"
          @current-change="changePage"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<style src="./index.scss" lang="scss"></style>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: false,
      list: [],
      pagination: {
        pageSize: 15,
        totalSize: 0,
        currentPage: 1
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    changePage(v) {
      this.getList(v);
    },
    async getList(v) {
      this.loading = true;
      const res = await this.$http.get(
        `/api/v1/Accounts/${this.account.id}/Orders`,
        {
          params: {
            page: v || this.pagination.currentPage,
            size: this.pagination.pageSize
          }
        }
      );
      this.list = res.data.list;
      this.pagination = res.data.pagebar;
      this.loading = false;
    },
    getScopType(status) {
      switch (status) {
        case this.$t("audit_refuse"):
        case this.$t("not_match"):
          return "danger";
        case this.$t("approved"):
          return "success";
        case this.$t("quit_midway"):
          return "";
        default:
          return "info";
      }
    }
  },
  computed: {
    ...mapGetters(["account"])
  }
};
</script>
